import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLastLogin, setUsername } from '../store/userSlice';
import '../App.css'; 
import backgroundImage from '../img/hotel3.png';

const Login = () => {
  const [username, setUsernameLocal] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(process.env.REACT_APP_BACKEND_URL);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set content type to JSON
          'x-api-key': process.env.REACT_APP_API_KEY, // Send API key in the headers
        },
        body: JSON.stringify({ username, password }), // Convert the data to JSON
      });
    
      if (!response.ok) {
        throw new Error('Invalid credentials, please try again.');
      }
    
      const data = await response.json(); // Parse response as JSON
      const { redirect } = data;
    
      dispatch(setUsername(username));
      dispatch(setLastLogin(new Date().toISOString()));
      navigate('/dashboard');
    } catch (err) {
      setError(err.message);
    }
    };

    return (
      <div className="relative min-h-screen flex justify-center items-center bg-gray-700">
        
        <div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full max-w-4xl p-8 space-y-6 md:space-y-0 md:space-x-8 bg-gray-100 rounded shadow-md">
          <div className="md:w-1/2">
            <img src={backgroundImage} alt="Background" className="w-full h-auto rounded-lg shadow-lg my-8 md:my-0 md:mr-8" />   
          </div>
          <div className="md:w-1/2">
            <h2 className="text-2xl font-bold mb-4">Login to your Account</h2>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <form className="space-y-6" onSubmit={handleLogin}>
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsernameLocal(e.target.value)}
                  className="w-full p-2 mt-1 border rounded focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                  required
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-2 mt-1 border rounded focus:ring focus:ring-indigo-200 focus:border-indigo-500"
                  required
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full py-2 text-white bg-gray-700 rounded hover:bg-green-900 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    );
}

export default Login;