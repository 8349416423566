// src/store/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    username: '',
    lastLogin: null,
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setLastLogin: (state, action) => {
      state.lastLogin = action.payload;
    },
    resetUser: (state) => {
      state.username = '';
      state.lastLogin = null;
    },
  },
});

export const { setUsername, setLastLogin, resetUser } = userSlice.actions;

export default userSlice.reducer;