/* Renders lowest, highest and my price for a given category */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetUser, setLastLogin, setUsername } from '../store/userSlice';
import PricesDisplayElement from './pricesDisplayElement';

const PricesDashboard = (props) => {  
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.username);
  const lastLogin = useSelector((state) => state.user.lastLogin);
  const navigate = useNavigate();

  /* Set current key for headline */
  const [currentKey, setCurrentKey] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  /* Lowest prices hooks */
  const [lowestPrice, setLowestPrice] = useState(null);
  const [lowestPriceOrigin, setLowestPriceOrigin] = useState(null);
  const [lowestPriceURL, setLowestPriceURL] = useState(null);
  /* Highest prices hooks */
  const [highestPrice, setHighestPrice] = useState(null);
  const [highestPriceOrigin, setHighestPriceOrigin] = useState(null);
  const [highestPriceURL, setHighestPriceURL] = useState(null);
  /* My price hooks */
  const [myPrice, setMyPrice] = useState(null);
  const [myPriceOrigin, setMyPriceOrigin] = useState(null);
  const [myPriceURL, setMyPriceURL] = useState(null);

  var data = props.data;
  var props_currentLocation = props.location;
  var props_currentKey = props.currentKey;

  useEffect(() => {
    /* Set current key */
    setCurrentKey(props_currentKey);
    setCurrentLocation(props_currentLocation);
    console.log(props_currentLocation);
  
    /* Set lowest price */
    setLowestPrice(data[props_currentLocation]?.["lowestlowestPrice"] ?? null);
    setLowestPriceOrigin(data[props_currentLocation]?.["lowestlowestpriceOrigin"] ?? null);
    setLowestPriceURL(data[props_currentLocation]?.["lowestlowestpriceUrl"] ?? null);
  
    /* Set highest price */
    setHighestPrice(data[props_currentLocation]?.["highestlowestPrice"] ?? null);
    setHighestPriceOrigin(data[props_currentLocation]?.["highestlowestpriceOrigin"] ?? null);
    setHighestPriceURL(data[props_currentLocation]?.["highestlowestpriceUrl"] ?? null);
  
    /* Set my price */ 
    setMyPrice(data[props_currentLocation]?.["myPrice"] ?? null);
    setMyPriceOrigin(data[props_currentLocation]?.["myPriceOrigin"] ?? null);
    setMyPriceURL(data[props_currentLocation]?.["myPriceUrl"] ?? "No Data");
  
  }, [data, props_currentLocation, props_currentKey]);
  
  const RedirectDetails = () => {
    const data = {
      endpoint: props_currentKey,
      current_location: props_currentLocation
    };
    console.log("From Dashboard: " + props_currentLocation);
    navigate("/details", { state:  data  });
  };

  const TranslateNames = () => {
    var returnMe = "";
    if(currentKey == "checkin_today")
    {
      return "Check-In Today";
    }
    else if(currentKey == "checkin_3weeks")
      {
        return "Check-In 3 Weeks";
      }
    
      else if(currentKey == "checkin_3months")
      {
        return "Check-In 3 Month";
      }
      else 
      {
        return currentKey;
      }
  }


  const DoesContainNumber= (str) => {

// Regular expression to check for any digit (0-9)
      const regex = /\d/;
          
      // Test the string against the regular expression
      return regex.test(str);

  };


 
  return (
  <div className="grid grid-cols-1 gap-4 bg-white  border-gray-200 p-4 md:grid-cols-2 lg:grid-cols-4">
    {currentKey && (
      <h2 className="col-span-full text-xl md:text-xl pl-2 border-l-4 font-sans font-bold border-teal-400 dark:text-gray-200">
        {TranslateNames()}
      </h2>
    )}

    <div className="lg:col-span-1 bg-green-100 p-1 shadow-md flex flex-col justify-between">
      <h5 className="mb-1 text-l font-extrabold leading-none tracking-tight text-gray-900 md:text-lg dark:text-white">
        Lowest Price:
      </h5>
      {lowestPrice && lowestPriceOrigin && lowestPriceURL && (
        <PricesDisplayElement displayText="Lowest Price" price={lowestPrice} origin={lowestPriceOrigin} url={lowestPriceURL} />
      )}
    </div>
    
    <div className="lg:col-span-1 bg-red-100 p-1 shadow-md flex flex-col justify-between">
      <h5 className="mb-1 text-l font-extrabold leading-none tracking-tight text-gray-900 md:text-lg dark:text-white">
        Highest Price:
      </h5>
      {highestPrice && highestPriceOrigin && highestPriceURL && (
        <PricesDisplayElement displayText="Highest Price" price={highestPrice} origin={highestPriceOrigin} url={highestPriceURL} />
      )}
    </div>

    <div className="lg:col-span-1 bg-gray-200 p-1 shadow-md flex flex-col justify-between">
      <h5 className="mb-1 text-l font-extrabold leading-none tracking-tight text-gray-900 md:text-lg dark:text-white">
        Your Price:
      </h5>
      {myPrice && myPriceOrigin && (
        <PricesDisplayElement displayText="My Price" price={myPrice} origin={myPriceOrigin} url={myPriceURL} />
      )}
    </div>

    <div className="lg:col-span-1 flex flex-col justify-center items-center">
      {currentKey && currentLocation && (
        <button
          type="button"
          className="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 from-neutral-200 text-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={RedirectDetails}
        >
          Details
          <svg
            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      )}

  

      
      <button
        className={`mt-4 text-white font-bold py-4 px-4 rounded-full ${
          !lowestPrice || !myPrice || !DoesContainNumber(String(myPrice)) || !DoesContainNumber(String(lowestPrice))
            ? 'bg-gray-500'
            : parseFloat(String(lowestPrice).replace(/[^0-9.-]+/g, "")) >= parseFloat(String(myPrice).replace(/[^0-9.-]+/g, ""))
            ? 'bg-green-500 hover:bg-green-700'
            : 'bg-red-500 hover:bg-red-700'
        }`} 
      ></button>
      
    </div>
    
    
  </div>
);
}; 


export default PricesDashboard;