import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const Graph = ({ dataset }) => {
  const groupedData = {};

  dataset.forEach((entry) => {
    const { Location, Date, Price, Website } = entry;
    const dateKey = Date.slice(0, 10);

    if (!groupedData[Location]) {
      groupedData[Location] = {};
    }

    if (
      !groupedData[Location][dateKey] ||
      Price < groupedData[Location][dateKey].price
    ) {
      groupedData[Location][dateKey] = { price: Price, website: Website };
    }
  });

  // Ensure the full range of dates is included, covering only valid dataset dates
  const uniqueDates = dataset.map((entry) => entry.Date.slice(0, 10));
  const minDate = new Date(Math.min(...uniqueDates.map((date) => new Date(date))));
  const maxDate = new Date(Math.max(...uniqueDates.map((date) => new Date(date))));

  // Generate all dates between minDate and maxDate, including the last date
  const labels = [];
  for (let date = new Date(minDate); date <= maxDate; date.setDate(date.getDate() + 1)) {
    labels.push(new Date(date).toISOString().slice(0, 10));
  }

  const locations = Object.keys(groupedData);

  const datasets = locations.map((location) => {
    const color = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
      Math.random() * 255
    )}, ${Math.floor(Math.random() * 255)}, 1)`; // Random color for each line

    return {
      label: location,
      data: labels.map(
        (label) => groupedData[location][label]?.price || null
      ),
      borderColor: color,
      backgroundColor: color, // Solid color for legend
      borderWidth: 2,
      tension: 0.2,
    };
  });

  const chartData = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows dynamic resizing
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const location = datasets[tooltipItem.datasetIndex].label;
            const date = labels[tooltipItem.dataIndex];
            const { price, website } = groupedData[location][date] || {};

            return [
              `Price: ${price || "N/A"}`,
              `Website: ${website || "Unknown"}`,
              `Location: ${location}`,
            ];
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Price",
        },
      },
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
    },
  };

  return (
    <div style={{ position: "relative", height: "80vh", width: "100%" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default Graph;
