/* Gets Rendered on every site, implemented on the top level "App.js" */

import React from 'react';

const Loading = () => {  

return (
    <div className="flex flex-col items-center justify-center h-60">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mb-4"></div>
    <h1 className="text-2xl font-semibold text-gray-700 dark:text-gray-300">Loading ...</h1>
  </div>
);
};

export default Loading;