import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetUser } from '../store/userSlice';
import { CapitalizeString } from '../utils/utils';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector((state) => state.user.username);

  const [isOpen, setIsOpen] = useState(false); // Dropdown state for mobile

  const handleLogout = () => {
    dispatch(resetUser());
    navigate('/');
  };

  return (
    <nav className="w-full bg-gray-800 text-white shadow-lg p-4">
      {/* Desktop and Main Header */}
      <div className="flex justify-between items-center">
        {/* User Info and Navigation Links */}
        <div className="flex items-center space-x-4">
          {/* User Info */}
          {username && (
            <div>
              Logged in as{' '}
              <span className="font-bold text-lg">
                {CapitalizeString(username)}
              </span>
            </div>
          )}

          {/* Navigation Links (Desktop) */}
          <div className="hidden md:flex space-x-4">
            <p
              onClick={() => navigate('/dashboard')}
              className="cursor-pointer hover:text-gray-300"
            >
              Dashboard
            </p>
            <p
              onClick={() => navigate('/history')}
              className="cursor-pointer hover:text-gray-300"
            >
              History(Preview)
            </p>
          </div>
        </div>

        {/* Logout Button (Desktop) */}
        <button
          onClick={handleLogout}
          className="hidden md:block text-white border border-gray-500 rounded px-4 py-2 hover:bg-gray-700"
        >
          Log Out
        </button>

        {/* Hamburger for Mobile */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-white md:hidden focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden flex flex-col space-y-4 mt-4">
          <p
            onClick={() => navigate('/dashboard')}
            className="cursor-pointer hover:text-gray-300"
          >
            Dashboard
          </p>
          <p
            onClick={() => navigate('/history')}
            className="cursor-pointer hover:text-gray-300"
          >
            History(Preview)
          </p>
          <button
            onClick={handleLogout}
            className="text-white border border-gray-500 rounded px-4 py-2 hover:bg-gray-700"
          >
            Log Out
          </button>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
