/* Acts as middleware for transition between sites */
/* Redirects to login, when locally stored data is not valid */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLastLogin } from '../store/userSlice';

const withAuthCheck = (WrappedComponent) => {
  return (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const username = useSelector((state) => state.user.username);
    const lastLogin = useSelector((state) => state.user.lastLogin);
    const navigate = useNavigate();

    useEffect(() => {
      if (!username || isLoginTooOld(lastLogin)) {
        navigate('/login');
      } else {
        dispatch(setLastLogin(new Date().toISOString()));
      }
    }, [username, lastLogin, history, dispatch]);

    const isLoginTooOld = (lastLogin) => {
      if (!lastLogin) return true;
      const loginDate = new Date(lastLogin);
      const currentDate = new Date();
      const diffInDays = (currentDate - loginDate) / (1000 * 60 * 60 * 24);
      return diffInDays > 5;
    };

    return username && !isLoginTooOld(lastLogin) ? (
      <WrappedComponent {...props} />
    ) : (
      <h1>Redirecting...</h1>
    );
  };
};

export default withAuthCheck;