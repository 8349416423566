/* Entry point of App */
/* Checks, if we have needed data saved in the local storage of the browser and if the last login is current enough */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetUser, setLastLogin, setUsername } from '../store/userSlice';

const Landing = () => {  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Get persist data stored */
  const username = useSelector((state) => state.user.username);
  const lastLogin = useSelector((state) => state.user.lastLogin);
  

  useEffect(() => {   
    /* Check for saved data and redirect accordingly */
    if (username == '' || isLoginTooOld(lastLogin)) {
        dispatch(resetUser());
        navigate('/login');
    } else {
      dispatch(setLastLogin(new Date().toISOString()));
        navigate('/dashboard');
    }
  }, [username, lastLogin, navigate, dispatch]);

  const isLoginTooOld = (lastLogin) => {
    if (!lastLogin) return true;
    const loginDate = new Date(lastLogin);
    const currentDate = new Date();
    const diffInDays = (currentDate - loginDate) / (1000 * 60 * 60 * 24);
    return diffInDays > 5;
  };

  return <h1>🧐</h1>;
};

export default Landing;