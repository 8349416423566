// src/store/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import counterReducer from './counterSlice';
import userReducer from './userSlice';

// Persist configurations for each reducer
const counterPersistConfig = {
  key: 'counter',
  storage,
};

const userPersistConfig = {
  key: 'user',
  storage,
};

// Persisted reducers
const persistedCounterReducer = persistReducer(counterPersistConfig, counterReducer);
const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

// Combine reducers
const rootReducer = combineReducers({
  counter: persistedCounterReducer,
  user: persistedUserReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const persistor = persistStore(store);