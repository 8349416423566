/* Single element for "pricesDashboard.jsx" */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../utils/utils';


const PricesDisplayElement = (props) => {  
  

   return (
    <>
      <h4 className="text-sm md:text-base lg:text-lg font-semibold text-black-700 dark:text-black-300">
        Price: <span className="text-sm md:text-base lg:text-lg text-black-600 dark:text-blue-400">{formatCurrency(props.price)}</span>
      </h4>
      <h4 className="text-sm md:text-base lg:text-lg font-semibold text-black-700 dark:text-black-300">
        Source: <a href={props.url} target='_blank' className="text-sm md:text-base lg:text-lg text-blue-600 dark:text-blue-400">{props.origin}</a>
      </h4>
      {/* {props.url != "No Data" &&       
      <a 
        href={props.url}
        target='_blank' 
        className="inline-flex items-center justify-center p-1 md:p-2 lg:p-3 text-sm md:text-base lg:text-lg font-medium text-gray-500 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        Link
      </a> */}
      
    </>
  );

};

export default PricesDisplayElement;