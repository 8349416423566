
  import React, { useEffect, useState } from 'react';
  import { useLocation } from 'react-router-dom';
  import { useSelector, useDispatch } from 'react-redux';
  import { useNavigate } from 'react-router-dom';
  import withAuthCheck from '../hoc/withAuthCheck';
  import DisplayAllData from '../components/displayAllData';
  import Loading from '../components/loading';
 
  
  const History = (props) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fetchedData, setFetchedData] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [requestedData, setRequestedData] = useState(false);
  const customer_name = useSelector((state) => state.user.username);

  // Get today's date to limit entry in input
  const today = new Date().toISOString().split("T")[0];


  const handleSubmit = async (e) => {
    e.preventDefault();
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    if(endDateObject < startDateObject)
    {
      setUserMessage("The From-Date has to be equal or smaller than the To-Date.");
      return;
    }
    
    console.log(typeof(startDateObject));

    if(fetchedData)
    {
      setFetchedData('');      
    }
    if(userMessage){
      setUserMessage('');
    }

    const capitalized = customer_name[0].toUpperCase() + customer_name.slice(1);
    setRequestedData(true);
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/history/' + capitalized + "/" + startDate + "/" + endDate, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json', // Set content type to JSON
        'x-api-key': process.env.REACT_APP_API_KEY, // Send API key in the headers
      }// Convert the data to JSON
    });
  
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
  
    const responseData = await response.json(); // Parse response as JSON   
    
    setTimeout(() => {
      console.log(responseData);
    }, 2000); // 2000 milliseconds = 2 seconds

    
    console.log("Hallo");
    setFetchedData(responseData);
    setRequestedData(false);

    // Perform any other actions with the start and end dates here
  };

  useEffect(() => {
    
    

    // Fetch data from the API
    /* fetch(process.env.REACT_APP_BACKEND_URL + "/data/" + endpoint + "/" + capitalized_customerName +"/" + current_location, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY // Send API key in the headers
        }
    })

      .then(response => response.json())
      .then(data => {
        console.log("Fetched data:", data); // Log the data to inspect its structure
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }); */
  }, []);
  
    
    return (
      <div className='px-4'>
        <div className="bg-yellow-300 text-yellow-900 text-center py-2 px-4 font-medium shadow-md">
      This feature is currently under development. You are viewing a preview.<br/>
      Only lowest prices of "Check-In Today" data can currently be viewed.
    </div>
        {userMessage && <p className='text-red-400 font-extrabold'>{userMessage}</p>}
       <div className=''>
       <div className="date-range-form mt-2">
      <form onSubmit={handleSubmit} className="grid grid-cols-2 grid-rows-2">
        <div className='px-1'>
          <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">
            From
          </label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full p-2 mt-1 border rounded focus:ring focus:ring-indigo-200 focus:border-indigo-500"
            min="2024-10-15"
            required
          />
        </div>
        <div className='px-1'>
          <label htmlFor="end-date" className="block text-sm font-medium text-gray-700">
            To
          </label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full p-2 mt-1 border rounded focus:ring focus:ring-indigo-200 focus:border-indigo-500"
            max={today}
            required
          />
        </div>
        <button
          type="submit"
          className=" w-full mx-auto mt-2  h-10 text-white bg-gray-700 rounded hover:bg-green-900 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 col-span-2"
        >
          Submit
        </button>
      </form>
    </div>

    </div>
    

    {fetchedData && !requestedData && <DisplayAllData data={fetchedData}/>}
    {!fetchedData && requestedData && <Loading />}
    {!fetchedData && !requestedData && <p>Please enter the dates above and click "Submit".</p>}
      </div>

          
    );
  };
  
  export default withAuthCheck(History);
 






 