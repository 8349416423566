
  import React, { useEffect, useState } from 'react';
  import { useLocation } from 'react-router-dom';
  import { useSelector, useDispatch } from 'react-redux';
  import { useNavigate } from 'react-router-dom';
  import withAuthCheck from '../hoc/withAuthCheck';
 
  
  const Details = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const customer_name = useSelector((state) => state.user.username);
  const capitalized_customerName = customer_name[0].toUpperCase() + customer_name.slice(1);

  const navigate = useNavigate();
  const location = useLocation();
  const mydata = location.state;
  var endpoint;
  var current_location;
  if(mydata != null)
    {
      endpoint = mydata.endpoint;
      current_location = mydata.current_location; 
    } 

  const HandleBackToDashboard = () => {
    navigate("/dashboard");
  }

  useEffect(() => {
    // Load WebFont
    /* const WebFont = require('webfontloader');
    WebFont.load({
      google: {
        families: ["Sen:regular,700,800"]
      }
    }); */

    if(mydata == null)
      {
        navigate("/dashboard")
      }
    

    // Fetch data from the API
    fetch(process.env.REACT_APP_BACKEND_URL + "/data/" + endpoint + "/" + capitalized_customerName +"/" + current_location, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY // Send API key in the headers
        }
    })

      .then(response => response.json())
      .then(data => {
        console.log("Fetched data:", data); // Log the data to inspect its structure
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      });
  }, [endpoint, mydata]);
  
    if (loading) return <div className="flex flex-col items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mb-4"></div>
    <h1 className="text-2xl font-semibold text-gray-700 dark:text-gray-300">Loading ...</h1>
  </div>
    if (error) return <div>Error loading data.</div>;
  
    const handleSourceClick = (source) => {
      setSelectedSource(selectedSource === source ? null : source);
    };
  
    const findLowestPrice = (entries) => {
      if (!Array.isArray(entries)) return null;
      const prices = entries.map(entry => parseFloat(entry.price)).filter(price => !isNaN(price));
      return prices.length > 0 ? Math.min(...prices) : null;
    };
    //This function below will work for google because it converts prices in strings to numeric. Needs adjustemnts sins highest price will always be from google due to currency.. 
    /* const findLowestPriceforSourceButtons = (entries) => {
      
      if (!Array.isArray(entries)) return null;
      const prices = entries.map(entry => {
        const price = entry.price || Object.values(entry)[0];
        return parseFloat(price.toString().replace(/[$,]/g, ''));
      }).filter(price => !isNaN(price));
      return prices.length > 0 ? Math.min(...prices) : null;
    }; */

    const findHighestLowestPrice = (data) => {
      let highestLowestPrice = -Infinity;
      let lowestLowestPrice = Infinity;
      let highestSource = null;
      let lowestSource = null;
  
      Object.keys(data).forEach(date => {
        Object.keys(data[date]).forEach(source => {
          try {
            const lowestPrice = findLowestPrice(data[date][source]);
            if (lowestPrice !== null) {
              if (lowestPrice < lowestLowestPrice) {
                lowestLowestPrice = lowestPrice;
                lowestSource = source;
              }
              if (lowestPrice > highestLowestPrice) {
                highestLowestPrice = lowestPrice;
                highestSource = source;
              }
            }
          } catch (error) {
            console.error(`Error processing source ${source} for date ${date}:`, error);
          }
        });
      });
  
      return { highestSource, lowestSource };
    };
  
    const { highestSource, lowestSource } = findHighestLowestPrice(data);
    
    
    return (
      <div>
        <div id="sites" className="menu-wrapper">
          <div className="navbar w-nav" role="banner">
          <div className="container">
            <div className="nav-wrapper flex items-center justify-between">
              <a onClick={HandleBackToDashboard} className="brand w-nav-brand hover:text-yellow-400 transition duration-300 flex items-center cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={3}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <h1 className="heading-9 text-xl font-bold">Back to Dashboard</h1>
              </a>
              <div className="cart-nav-wrapper">
                <div id="sites" className="nav-button w-nav-button">
                  <div className="w-icon-nav-menu"></div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>       
        <div className="content-section why-acme-sections">
          <div className="container">
            <div className="about-top-block">
              <div className="why-acme-block">
                <h2 className="why-acme-heading">
                {capitalized_customerName && current_location && <>{capitalized_customerName} | {current_location}</>}
                  </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section">
          <div className="container">
            <div className="container mx-auto p-4 sm:p-6">
             <div className="container-flex flex flex-col lg:flex-row">
              <div className="sources-list w-full lg:w-1/2 lg:pr-4">
                {data && Object.keys(data).length === 0 && <div>No data available.</div>}
                {data && Object.keys(data).map(date => (
                  <div key={date}>
                    <h3>{date}</h3>
                    {Object.keys(data[date]).map(source => {

                      // Find the lowest price for the current source and date
                      const lowestPrice = findLowestPrice(data[date][source]);

                      return (

                            <div key={source} className="mb-5 flex flex-col sm:flex-row items-center sm:items-start">
                              <button onClick={() => handleSourceClick(source)}
                                 className={`source-button w-full sm:w-52 p-3 mb-2 sm:mb-0 sm:mr-2 text-white rounded-lg shadow-md transition-transform transform hover:scale-105 ${source === highestSource ? 'bg-red-500 hover:bg-red-600' : source === lowestSource ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'}`}
                                 >
                                  
                                
                              {source} </button>
                              {/* Display the lowest price next to the button */}
                              {source !== "Google.Com" && (
                                <span className={`text-center sm:text-left text-sm font-medium ${lowestPrice !== null ? 'text-green-600' : 'text-red-600'}`}>
                                  Lowest Price: {lowestPrice !== null ? `€${lowestPrice}` : 'No Prices'}
                                </span>
                              )}
                              </div>
                          )
                        })}
                    </div>
                  ))}
                </div>
              <div className="data-table w-full lg:w-1/2 mt-4 lg:mt-0">
                {selectedSource && (
                  <div>
                    <h4>Data for {selectedSource}</h4>
                    {!Array.isArray(data[Object.keys(data)[0]][selectedSource]) ? (
                    <div>Source data unavailable</div>
                  ) : (
                    selectedSource === "Google.Com" ? (
                      <div className="overflow-x-auto mt-4 sm:mt-0">
                        <table className="border-collapse w-full mt-4 sm:mt-0">
                          <thead>
                            <tr>
                              <th className="border border-gray-300 p-2 text-sm sm:text-base">Booking Site</th>
                              <th className="border border-gray-300 p-2 text-sm sm:text-base">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data[Object.keys(data)[0]][selectedSource].filter(entry => !entry.TimeStamp)

                            //removing dollar sign and comma before Sorting table based on prices ascending order
                            .sort((a, b) => {
                              const priceA = parseFloat(Object.values(a)[0].replace(/[$,]/g, ''));
                              const priceB = parseFloat(Object.values(b)[0].replace(/[$,]/g, ''));
                              return priceA - priceB;
                            })
                            .map((entry, index) => (
                              <tr key={index} className="border border-gray-300">
                                <td className="border border-gray-300 p-2 text-sm sm:text-base">
                                  {Object.keys(entry)[0]}
                                </td>
                                <td className="border border-gray-300 p-2 text-sm sm:text-base">
                                  {entry[Object.keys(entry)[0]]}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                    
                    <div className="overflow-x-auto">
                    <table className="border-collapse w-full mt-4 sm:mt-0">
                      <thead>
                        <tr>
                          {Object.keys(data[Object.keys(data)[0]][selectedSource][0]).map((key, index) => (
                            <th key={index} className="border border-gray-300 p-2 text-sm sm:text-base">{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data[Object.keys(data)[0]][selectedSource]
                        //Sort tables based on prices ascending order
                        .sort((a, b) => {
                          const priceA = parseFloat(a.price || 0);
                          const priceB = parseFloat(b.price || 0);
                          return priceA - priceB;
                        })
                        .map((entry, index) => {
                          const lowestPrice = findLowestPrice(data[Object.keys(data)[0]][selectedSource]);
                          return (
                            <tr key={index} className="border border-gray-300">
                              {Object.entries(entry).map(([key, value], index) => (
                                <td key={index} style={{
                                  border: '1px solid #dddddd',
                                  padding: '8px',
                                  backgroundColor: key === 'price' && parseFloat(value) === lowestPrice ? 'green' : 'transparent',
                                  color: key === 'price' && parseFloat(value) === lowestPrice ? 'white' : 'black'
                                }}>
                                  {key === 'URL' ? (
                                    <a href={value} target="_blank" rel="noopener noreferrer">Link</a>
                                  ) : (
                                    value
                                  )}
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    </div>
                    )
                    )}
                  </div>
                )}
              </div>
              </div>
            </div>
          </div>
        </div>

      <footer className="flex flex-col items-center bg-zinc-50 text-center text-surface dark:bg-neutral-700 dark:text-white">
        <div className="w-full bg-black/5 p-4 text-center">
        © 2024 Copyright:
        <a href="https://tw-elements.com/"> Golden Pheasant</a>
        </div>
        </footer>       
      </div>

          
    );
  };
  
  export default withAuthCheck(Details);
 






 