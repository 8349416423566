// src/App.js
import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { increment, decrement, incrementByAmount } from './store/counterSlice';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './sites/landing';
import Login from './sites/login';
import Dashboard from './sites/dashboard';
import History from './sites/history';
import withAuthCheck from './hoc/withAuthCheck';
import NavBar from './components/navBar';
import Details from './sites/details';

function App() {
  const count = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();

  const username = useSelector((state) => state.user.username);

  return (    
    <Router>
    {username && <NavBar/>}
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/history" element={<History />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/details" element={<Details />} />
      </Routes>
    </Suspense>
  </Router>
  );
}

export default App;