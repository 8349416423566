/* Initial page after successfull login */
/* Data fetch: "/dashboard/{customer}" */

import withAuthCheck from '../hoc/withAuthCheck';
import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LocationButton from '../components/loactionButton';
import PricesDashboard from '../components/pricesDashboard';
import Loading from '../components/loading';
import Image1 from '../img/banquet-table-with-snacks.jpg';
import Image2 from '../img/comfortable-modern-bedroom-with-elegant-decoration-lighting-generative-ai.jpg';
import Image3 from '../img/pexels-asadphoto-1268855.jpg';
import Image4 from '../img/felix-rostig-UmV2wr-Vbq8-unsplash.jpg';
import Image5 from '../img/istockphoto-1193140198-612x612.jpg';
import Image6 from '../img/pexels-mikhail-nilov-7820376.jpg';
import '../App.css'; 

const Dashboard = () => {
    /* Get data from Redux Persist Store */
    const username = useSelector((state) => state.user.username);

    /* Initialize Hooks */
    const [capitalizedUsername, setCapitalizedUsername] = useState(null);
    const [todayDashboardData,setTodayDashboardData] = useState(null);
    const [fetchingError, setFetchingError] = useState(null);
    const [currentChosenLocation, setCurrentChosenLocation] = useState(null);
    const [allCustomerLocations, setAllCustomerLocations] = useState(null);
    const [allData, setAllData] = useState(null);
    const [allDataKeys, setAllDataKeys] = useState(null);
    const [mostRecentTimestamp, setMostRecentTimestamp] = useState(null);

    /* Function to change the local view for the dashboard on button click */
    const handleLocationChange = (newLocation) =>
        {
          setCurrentChosenLocation(newLocation);          
        }
    const updateMostRecentTimestamp = (data, location) => {
        for (const key in data) {
            if (data[key][location]) {
                setMostRecentTimestamp(data[key][location].mostRecentTimestamp);
                break;
            }
        }
    };
    
    useEffect(() => {
        // For the API call and to display, we have to capitalize the username
        if (username) {
            const capitalized = username[0].toUpperCase() + username.slice(1);
            setCapitalizedUsername(capitalized);

            /* API call */            
            fetch(process.env.REACT_APP_BACKEND_URL + '/dashboard/' + capitalized, {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY // Send API key in the headers
            }
        })

                .then(response => response.json())
                .then(data => {
                    /* Refactor Data and feed hooks */
                    setAllData(data);         
                    var firstLayerKeys = Object.keys(data);  
                    setAllDataKeys(Object.keys(data));
                    /* Set Location */
                    var allLocations = Object.keys(data[firstLayerKeys[0]]);
                    setAllCustomerLocations(allLocations);
                    if(currentChosenLocation == null)
                    {
                        setCurrentChosenLocation(allLocations[0]);
                        updateMostRecentTimestamp(data, allLocations[0]);
                    }
                    
                }
                )
                .catch(error => {
                    setFetchingError("Could not reach server. If the error persist, please inform an administrator.")
                console.error("Error fetching data for Check-in Today:", error);
        });           
        }
    }, [username]); 

    
    
    return (
        
            <>
            {!allData && (
  <Loading/>
)}
            <div class="md:container md:mx-auto">
            <br></br>      
            {/* Render location Buttons */}
            {allCustomerLocations && allCustomerLocations.length > 0 ? (
    <>
      <label htmlFor="location-dropdown" className="block mb-2 ml-2 text-lg font-medium text-gray-900 dark:text-white">
        Choose a location:
      </label>
      <select
        id="location-dropdown"
        className="mx-auto block w-full p-2 mb-0 text-lg font-bold border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        onChange={(e) => handleLocationChange(e.target.value)}
        defaultValue={allCustomerLocations[0]} // Set the first location as the default
      >
        {allCustomerLocations.map((location, index) => (
          <option key={index} value={location}>
            {location}
          </option>
        ))}
      </select>
    </>
  ) : (
    <></>
  )}        
            {/* Welcome Section */}
            {/* <h1 class="mb-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold text-gray-900 dark:text-white">
            <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Welcome</span> {capitalizedUsername ? capitalizedUsername : ''}!</h1> */}
            {mostRecentTimestamp ? 
                        <span class="ml-4 block sm:inline sm:ml-4 text-sm sm:text-md font-normal text-gray-700 dark:text-gray-400"><br></br>
                            Data from: <p className='font-bold text-lg'>{mostRecentTimestamp}</p>
                        </span> 
                        : ''}
            {/* Fetching Error handling */}
            {fetchingError ? <h5>An Error occured ...</h5>
            :<></>} 
            {/* Render Dashboards */}
            {allDataKeys && currentChosenLocation &&
             allDataKeys.map((key, index) => (
                <>
                <PricesDashboard key={index} currentKey={key} data={allData[key]} location={currentChosenLocation}/>
                <div className="relative">
      <div className="absolute inset-0 flex items-center">
        <div className={`w-full border-t border-gray-300`}></div>
      </div>
      
    </div>
                </>
            ))
           
            } 

            
            </div>

            <br></br><br></br>
            <footer className="flex flex-col items-center bg-zinc-50 text-center text-surface dark:bg-neutral-700 dark:text-white">
            <div className="container p-6">
                <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
                <div className="mb-6 lg:mb-0">
                    <img
                    src={Image1}
                    className="w-full h-48 object-cover rounded-md shadow-lg"
                    alt="City view 113"
                    />
                </div>
                <div className="mb-6 lg:mb-0">
                    <img
                    src={Image2}
                    className="w-full h-48 object-cover rounded-md shadow-lg"
                    alt="City view 111"
                    />
                </div>
                <div className="mb-6 lg:mb-0">
                    <img
                    src={Image3}
                    className="w-full h-48 object-cover rounded-md shadow-lg"
                    alt="City view 112"
                    />
                </div>
                <div className="mb-6 lg:mb-0">
                    <img
                    src={Image4}
                    className="w-full h-48 object-cover rounded-md shadow-lg"
                    alt="City view 114"
                    />
                </div>
                <div className="mb-6 lg:mb-0">
                    <img
                    src={Image5}
                    className="w-full h-48 object-cover rounded-md shadow-lg"
                    alt="City view 115"
                    />
                </div>
                <div className="mb-6 lg:mb-0">
                    <img
                    src={Image6}
                    className="w-full h-48 object-cover rounded-md shadow-lg"
                    alt="City view 116"
                    />
                </div>
                </div>
            </div>
            

          
      <div className="w-full bg-black/5 p-4 text-center">
        © 2024 Copyright:
        <a href="https://tw-elements.com/"> Golden Pheasant</a>
      </div>
    </footer>     
        </>
        



        
    );
}

export default withAuthCheck(Dashboard);

